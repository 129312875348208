<template>
    <div class="container">
        <qy-top-nav title="订单支付"/>
        <div class="qrcode"><qr-code :appSrc="qrCode" :logoSrc="logoImg" :size="200"></qr-code></div>
        <div class="qymsg">支付金额：{{payMoney}}元</div>
        <div class="qymsg" v-text="qrMsg"></div>
    </div>
</template>
<script>
    import request from "@/utils/request";
    import QrCode  from "@/views/qrCode/qrCode";
    export default {
        name: "PayGoodsOrder",
        components:{
            QrCode
        },
        data(){
            return{
                logoImg:"/img/logo.25a7da1e.png",
                qrMsg : "长按二维码识别进行支付",
                payMoney:this.getUrlParam("payMoney") || 0.00,
                qrCode :  decodeURIComponent(this.getUrlParam("qrCode")),
                payId:this.getUrlParam("payId")
            }
        },
        created(){
          var that=this;
          setTimeout(function(){
             that.queryStatus();
          },2000);
        },
        methods:{

            creatQrCode() {
                let data = decodeURIComponent(this.qrCode);
                //直接跳转微信支付
                location.href=data;
                let payImg = "http://121.41.18.160:8078/111.aspx?con="+data;
                this.qrMsg="长按二维码识别进行支付";
                this.payMoney = this.payMoney;
                this.innerHtml = "<img src='"+payImg+"'/>";
            },
            queryStatus(){
                if(!this.payId){
                    return;
                }
                request.post("/api/userPay/queryUserPayById/"+this.payId,(rs)=>{
                    if(rs.success&&rs.data.status){
                        this.$router.push({path:"/orderList?status=1"});
                    }
                })
            }
        }
    }
</script>
<style scoped>
    div.container{
        text-align: center;
        padding: 0 0;
        margin: 0 0;
        font-size: 14px;
    }
    .qrcode{
        display: inline-block;
        margin-top:20%;
    }
    .qymsg{
        font-size: 14px;
        font-weight: bold;
        padding-top: 5px;
    }
</style>
